<template>
    <caja>
      <h1>Si tu dirección estaba en nuestra base de datos, ha sido eliminada. <br><br>Lamentamos los inconvenientes.</h1>
    </caja>
</template>

<script>
import Caja from './Caja.vue'
export default {
  components: { Caja },
}
</script>
